import Vcode from "./verify.vue";
import MobileIndex from "./MobileIndex.vue";
import UsefulHelp from "./UsefulHelp.vue";
import Img1 from "./images/verify1.jpg";
import Img2 from "./images/verify2.jpg";
import Img3 from "./images/verify3.jpg";
import Img4 from "./images/verify4.jpg";
import Img5 from "./images/verify5.jpg";

export default {
    name: "Login",
    components: { Vcode, MobileIndex, UsefulHelp },

    data() {
        return {
            sysName: '',
            mainPath: '', // require('./images/logo.png')
            loginPath: '', // require('./images/main.png')

            hasLoading: false,
            hasLoadings: [],
            hasCreateLoadings: false,
            loginForm: {
                phone: "",
                password: "",
                hasValidate: false,
            },
            loginFormRules: {
                phone: [
                    {
                        required: true,
                        message: "请输入员工账号",
                        trigger: "blur",
                    },
                ],
                password: [
                    {
                        required: true,
                        message: "请输入密码",
                        trigger: "blur",
                    },
                ],
                hasValidate: [
                    {
                        required: true,
                        validator: (rule, value, callback) => {
                            if (!this.validateStatus) {
                                callback(new Error("请点击验证"));
                            } else {
                                callback();
                            }
                        },
                        trigger: "change",
                    },
                ],
            },
            validateStatus: false,
            hasResetValidateStyle: false,
            codeimgs: [Img1, Img2, Img3, Img4, Img5],
            isShow: false,
            showMobileIndex: false,
            showHelp: false,
            showResetDia: false,
            PB: {
                account: ''
            },
            rules: {
                account: { required: true, message: "请输入登录账号", trigger: "blur" },
            },
        };
    },


    watch: {
        validateStatus(nval) {
            if (nval) this.$refs.loginForm.clearValidate("hasValidate");
        },
    },
    created() {
        this.initPage()
    },

    methods: {
        async initPage() {
            const loading = this.$loading();
            const res = await this.$axios({
                url: "/api/initPage",
                method: "get",

            });
            if (res.code === 2000) {
                loading.close();
                console.log('接口请求完成');
                localStorage.setItem("sysIconMap", JSON.stringify(res.data.sysIconMap));
                if (res.data.sysIconMap) {
                    if (res.data.sysIconMap.mainPath) {
                        this.mainPath = this.$baseURL + '/' + res.data.sysIconMap.mainPath
                    } else {
                        this.mainPath = require('./images/logo.png')
                    }

                    if (res.data.sysIconMap.loginPath) {
                        this.loginPath = this.$baseURL + '/' + res.data.sysIconMap.loginPath
                    } else {
                        this.loginPath = require('./images/main.png')
                    }

                    if (res.data.sysIconMap.name) {
                        this.sysName = res.data.sysIconMap.name
                    } else {
                        this.sysName = '中心平台'
                    }

                    if (res.data.sysIconMap.sysPath) {
                        const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
                        link.type = 'image/x-icon';
                        link.rel = 'shortcut icon';
                        link.href = this.$baseURL + '/' + res.data.sysIconMap.sysPath;
                        document.getElementsByTagName('head')[0].appendChild(link);
                    } else {
                        const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
                        link.type = 'image/x-icon';
                        link.rel = 'shortcut icon';
                        // link.href = require('./images/favicon.ico');
                        link.href = require('./images/logo.png');
                        document.getElementsByTagName('head')[0].appendChild(link);

                    }
                }

            } else {
                loading.close();
                this.$message({
                    message: res.msg || "请求数据失败",
                    type: "warning",
                });
            }
        },

        // 重置密码
        handleResetDia() {
            this.PB.account = ''
            this.showResetDia = true
        },
        handClose() {
            this.PB.account = ''
            this.showResetDia = false
            setTimeout(() => {
                this.$refs.form.clearValidate();
            }, 20);
        },
        async handleSave() {
            try {
                await this.$refs.form.validate();
                this.$confirm("您确定要重置该账号的密码吗？", "系统提示").then(() => {
                    this.resectPsd()
                })
                    .catch(() => {
                        console.log("取消");
                    });
            } catch (reason) {
                reason && this.$message({
                    message: reason.msg || "操作失败",
                    type: "error"
                });
            }
        },
        async resectPsd() {
            const loading = this.$loading({
                target: ".showResetDia .el-dialog",
            });
            const res = await this.$axios({
                url: "/api/resetPassword",
                method: "post",
                data: {
                    account: this.PB.account
                },
            });
            loading.close();
            if (res.code === 2000) {
                console.log('接口请求完成');
                this.$message({
                    message: "重置密码成功，请至金鼎时代网络微信消息提醒中查看。",
                    type: "success"
                });
                this.handClose()
            } else {
                this.$message({
                    message: res.msg || "请求数据失败",
                    type: "warning",
                });
            }
        },




        createLoadingData(count) {
            const hasLoadings = [];
            for (let i = 0; i < count; i++) {
                hasLoadings.push(false);
            }
            this.hasCreateLoadings = true;
            this.hasLoadings = hasLoadings;
        },
        loading(currIdx) {
            if ((this.hasCreateLoadings && currIdx !== null) || currIdx !== undefined) {
                this.hasLoadings[currIdx] = true;
                return;
            }
            this.hasLoading = true;
        },
        loaded(currIdx) {
            if (
                (this.hasCreateLoadings && currIdx !== null) ||
                currIdx !== undefined
            ) {
                this.hasLoadings[currIdx] = false;
                return;
            }
            this.hasLoading = false;
        },
        handleSubmitLogin() {
            this.$refs.loginForm.validate((valid) => {
                if (valid) {
                    this.isShow = true;
                } else {
                    return false;
                }
            });
        },
        onSuccess() {
            this.loading();
            const CryptoJS = window.CryptoJS;

            console.log(window.CryptoJS);

            // var key       = CryptoJS.enc.Utf8.parse("CENTER001");
            var key        = CryptoJS.enc.Utf8.parse("YIMING5AUS900001");
            var srcs       = CryptoJS.enc.Utf8.parse(this.loginForm.password);
            var encrypted  = CryptoJS.AES.encrypt(srcs, key, {mode:CryptoJS.mode.ECB,padding: CryptoJS.pad.Pkcs7});
            const password = encrypted.toString();
            // console.log('password', password);

            this.$axios({
                url: "/api/login",
                method: "post",
                data: {
                    account: this.loginForm.phone,
                    password
                }
            })

            .then((res) => {
                if (res.code === 2000) {
                    // console.log(res);
                    // this.$store.commit("setToken", res.data.token);
                    // const {userLoginDTO: userSession} = res.data;
                    // const {cenResourceList: pathList} = userSession;
                    // // #region 这一部分代码转化后端数据结构为本地数据结构
                    // // 因为前期写目录功能的时候还没有动态目录的数据接口，所
                    // // 以这里后来接到的数据结构和本地数据结构不一样。
                    // pathList.forEach(item => {
                    //     if (item.icon) item.icon_path = this.$baseURL + "/" + item.icon;
                    //     item.iconClass = item.webPath?.split("/")?.[1];
                    //     item.name = item.title = item.menuName;
                    //     item.api = item.path;
                    //     item.path = item.webPath;
                    // });
                    // this.$store.commit("setUserInfo", userSession);
                    this.$store.dispatch("storeUser", res)
                    this.$router.replace({ path: "/home" });
                } else {
                    this.$message({
                        message: res.msg || "登录失败，请稍后重试",
                        type: "error"
                    });
                    this.resetValidateStyle();
                }
                this.loaded();
            })

            .catch(reason => {
                this.$message({
                    message: "登录失败，请稍后重试",
                    type: "error"
                });
                console.warn("登录程序出错", reason)
                this.resetValidateStyle();
                this.loaded();
            })
            .then(() => {
                this.isShow = false;
            })
        },
        resetValidateStyle() {
            this.validateStatus = false;
            this.hasResetValidateStyle = true;
        },

        handleHelpButton () {
            this.showHelp = true;
        },

        handleMobileIndexButton () {
            this.showMobileIndex = true;
        }
    },

};