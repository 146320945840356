// 一张默认头像
import BindWx from "@/components/BindWx/BindWx.vue";
import CertificateLeading from "@/components/CertificateLeading/CertificateLeading.vue";
const defaultAvatar = require("./images/avatar.png");
import { menuList } from "../../../router/menu";

export default {
	name: "Layout",
	components: {
		BindWx,
		CertificateLeading
	},
	inject: ["appReload"],
	provide () {
		return {
			fetchTeamAccountList: this.fetchAccountList
		}
	},

	data: () => ({
		// menu: menuList,
		menu: [menuList[0]],
		isCollapse: true,
		defaultAcitve: "", // 当前激活的导航
		expandMenu: null, // 当前展开的二级菜单
		defaultAvatar,    // 默认头像
		breadList: [],
		showBindWx: false,
		showLeadingPop: false,
        teamAccountList: [],
        sysIconMap: '',
        sysName: '',
        mainPath: '', // require('./images/logo.png')
	}),

	computed: {
		// ...mapState(["breadList"]), // 面包屑
		asideWidth() {
			return (this.isCollapse ? 64 : 200) + "px"
        },
        // (有的从业务系统跳过来访问公用页面不需要导航)
        showAside() {//是否显示头部导航 
            return this.$store.state.showMenu
        },
        showHeader() {//是否显示左侧导航
            return this.$store.state.showMenu
        }
	},

	created() {
		this.generateMenu();

		// 获取全局配置信息：系统列表
		this.fetchSystemEnum();

		// 设置当前展开的二级菜单
		if (location.pathname) {
			this.expandMenu = "/" + location.pathname.split("/")[1]; // 第一个为空字符串，因为开头为/
        }
        this.sysIconMap = JSON.parse(localStorage.getItem("sysIconMap"))
        if (this.sysIconMap) {
            if (this.sysIconMap.mainPath) {
                this.mainPath = this.$baseURL + '/' + this.sysIconMap.mainPath
            } else {
                this.mainPath = require('./images/logo.png')
            }
            if (this.sysIconMap.name) {
                this.sysName = this.sysIconMap.name
            } else {
                this.sysName = '中心平台'
            }

        }
	},

	mounted () {
		if (!(this.userInfo.unionidShangao && this.userInfo.unionidJinding)) this.showBindWx = true;
		this.teamAccountList = this.userInfo.teamAccountList;
		// this.fetchAccountList()
	},

	methods: {
		// 根据用户的权限列表生成目录
		generateMenu() {
			const { cenResourceList } = this.userInfo;
			/**
			* 用户url权限数据，根据升序排序
			* 再把 子数据 父级的 children 中
			*/
			const navUrlList = [...(cenResourceList||[])];
			navUrlList.sort((prev, next) => {
				if (prev.type != next.type) {
					return prev.type - next.type;
				} else {
					return prev.order - next.order;
				}
            });
			let i = navUrlList.length - 1;
			while (i >= 0 && navUrlList[i].type > 0) {
				const curItem = navUrlList[i];
				for (let j = i - 1; j >= 0; j--) {
					if (curItem.parentId == navUrlList[j].id) {
						if (!(navUrlList[j].children instanceof Array)) {
							navUrlList[j].children = [];
						}
						// 此处 push 会使排序颠倒
						navUrlList[j].children.unshift(curItem);
						navUrlList.length = i;
						break;
					}
				}
				i--;
			}
			this.menu.splice(1, this.menu.length, ...navUrlList.filter(item => 
				!(item.path === "/home" && item.children))); // 排除掉主页那个模块，因为那个模块的结构和其他模块的结构不一致，我们用固定this.menu[0]的代替
			this.defaultAcitve = this.extractTop2Path();
            // console.log(this.menu);
		},

		// 获取常用的配置信息，在 store 中统一管理
		async fetchSystemEnum() {
            try {
                const res = await this.$axios({
                    url: "/api/system/querySysAll"
                })

				if (res.code === 2000) {
					const list = res.data.systemList;
                    this.$store.commit("setSystemEnum", list);
				} else if (res.code !== 1003)
					throw res

            } catch (reason) {
                console.warn("获取系统选项", reason);
            }
		},

		
        async fetchAccountList () {
			console.log("获取登录账号的关联账号...");
            const loading = this.$loading();

            try {
				const res = await this.$axios({
					url: "/api/employee/getTeamAccount",
					method: "post",
					data: {
						empid: this.userInfo.employeeId
					}
				});

				if (res.code === 2000) {
					this.teamAccountList = res.data.teamAccountList;
					this.$store.dispatch("setTeamAccount", res.data.teamAccountList);

					loading.close();
				} else if (res.code !== 1003)
					throw res

			} catch (reason) {
				console.warn("获取数据失败", reason);
				this.$message({
					message: reason.msg || "获取数据失败",
					type: "warning"
				});

				loading.close();
			}
        },

		// 切换导航折叠状态
		toggleCollapse(value) {
			if (typeof value === "boolean") this.isCollapse = value;
			else this.isCollapse = !this.isCollapse
		},

		// 二级菜单展开的事件处理函数
		handleSubMenuOpen(path) {
			if (this.expandMenu && this.expandMenu !== path) {
				this.$refs.menu.close(this.expandMenu);
			}
			this.expandMenu = path;
		},

		// 点击 双关认证
		handleLeadingBtn () {
			this.showLeadingPop = true
		},

		// 点击账号
		async handleAccountClick (account) {
			console.log("切换账号", account);
            const loading = this.$loading();

            try {
				const res = await this.$axios({
					url: "/api/changeLogin",
					method: "post",
					data: {
						empid: account.empid
					}
				});

				if (res.code === 2000) {
                    this.$store.dispatch("storeUser", res)
                    if (this.$route.path !== "/home") await this.$router.replace({ path: "/home" });
					await this.appReload();

					loading.close();
				} else if (res.code !== 1003)
					throw res

			} catch (reason) {
				console.warn("操作失败", reason);
				this.$message({
					message: reason.msg || "操作失败",
					type: "warning"
				});

				loading.close();
			}
		},

		// 点击头像
		handleAvatarClick() {
			if (this.$route.path !== "/setting")
				this.$router.push("/setting");
		},

		// 点击退出
		async handleSignOut() {

			try {
				await this.$confirm('确定要退出登录吗?', '提示');
				const res = await this.$axios({
					url: "/api/outSystem",
					method: "post"
				})

				if (res.code === 2000) {
					this.$store.dispatch("unstoreUser");
				} else throw res;

			} catch (reason) {
				if (reason != "cancel") {
					console.warn("退出失败, reason");
				}
			}
		},

		// 提取当前匹配的路由的前两层："/basic/banner/edit/2" -> "/basic/banner"
		// 用于设置目录的当前高亮项
		extractTop2Path(path) {
			path = path || this.$route.fullPath;
			return path.match(/^(\/\w*){1,2}/)[0]
		}

	},

	watch: {
		"$route": {
			immediate: true,
			handler: function (val) {
				this.defaultAcitve = this.extractTop2Path(val.fullPath);

				// 更新面包屑
				const { cenResourceList } = this.userInfo;
				if (val.fullPath == "/home")
					return this.breadList = [{
						name: "首页",
						to: ""
					}];

				let breadList = [],
					currPath  = "",
					restPath  = val.fullPath.split("/").filter(
						dName=>(Boolean(dName)&&!(/^\d+(\?.*)?$/.test(dName)))),
					flag      = true;

				while (restPath.length) {
					const dName = restPath.shift()
					currPath += ("/" + dName);
					if (dName == "add") {
						breadList.push({
							name: "新增",
							to: ""
						})
					}
					else if (dName == "edit") {
						breadList.push({
							name: "编辑",
							to: ""
						})
					}
					else {
						const item = cenResourceList.find(it => it.webPath == currPath);
						if (item) breadList.push({
							name: item.menuName,
							// to: flag || (currPath == val.fullPath) ? "" : currPath
							to: flag || (!restPath.length) ? "" : currPath
						})
						else {
							breadList.push({
								name: val.meta.title,
								// to: flag || (currPath == val.fullPath) ? "" : currPath
								to: flag || (!restPath.length) ? "" : currPath
							});
							break;
						}
						flag = false;
					}
				}
				this.breadList = breadList;
			}
		}
	}
};
