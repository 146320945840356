import Vue from 'vue'
import Vuex from 'vuex'
import router from "../router"
import {baseURL} from "../request";
Vue.use(Vuex)

const store = new Vuex.Store({

	state: {
		token        : "",    // 凭证
		// breadList    : [],    // 当前面包屑数据
		userInfo     : {},    // 登录用户信息
		listPageState: null,  // 列表页记录状态, 便于从详情页返回时恢复
		systemEnum   : [],    // 系统（枚举）列表
        showMenu: true,
	},

	mutations: {

		// 设置token
		setToken: function (state, val) {
			state.token = val;
			localStorage.setItem("token", val);
		},

		// 跟新面包屑
		// setBreadList: function (state, list) {
		// 	if (!(list instanceof Array)) {
		// 		return console.warn("store 设置面包屑失败，传入的参数不是数组", list);
		// 	}
		// 	state.breadList = list;
		// },

		// 更新登录用户信息
		setUserInfo(state, info) {
			if (info.constructor !== Object) {
				return console.warn("store 设置用户信息失败，传入的参数不是对象", info)
			}
			if (info !== state.userInfo) {
				state.userInfo = Object.assign(state.userInfo, info);
			}
			localStorage.setItem("userInfo", JSON.stringify(info));
		},

		// 清空用户信息，在退出登录时使用
		clearUserInfo(state) {
			state.userInfo = {};
			state.token = "";
			localStorage.removeItem("token");
			localStorage.removeItem("userInfo");
			console.log("用户信息清空了");
		},

		// 记录列表页的状态
		saveListPageState(state, data) {
			if (data == undefined) {
				state.listPageState = null;
				console.log("已清空列表页状态")
			} else {
				state.listPageState = data;
				console.log("保存列表页状态", data);
			}
		},

		// 更新系统（枚举）列表
		setSystemEnum(state, arr) {
			if (arr instanceof Array) {
				state.systemEnum = arr

			} else if (arr == undefined) {
				state.systemEnum = []

            } else {
                console.log("忽略了对系统枚举列表的更新, 因为数据不正确", arr);
            }
        },
        // 显示隐藏菜单 showMenu
        setShowMenu(state, data) {
            state.showMenu = data.showMenu;
        }
	},

	actions: {
		storeUser ({commit}, res) {
			console.log("store user..", res);
			commit("setToken", res.data.token);
			const {userLoginDTO: userSession} = res.data;
			const {cenResourceList: pathList} = userSession;
			pathList.forEach(item => {
				if (item.icon) item.icon_path = baseURL + "/" + item.icon;
				item.iconClass = item.webPath?.split("/")?.[1];
				item.name = item.title = item.menuName;
				item.api = item.path;
				item.path = item.webPath;
			});
			userSession.teamAccountList = userSession.teamAccountList || [];
			commit("setUserInfo", userSession);
		},

		unstoreUser ({commit}) {
			commit("clearUserInfo");
			console.log("转到登录页...");
			router.replace("/login");
		},

		setTeamAccount ({commit, state}, list) {
			console.log("更新关联账号列表", list);
			if (typeof account !== "object") return;
			state.userInfo.teamAccountList = list;
			commit("setUserInfo", state.userInfo);
		},
		
		// 下面两个有响应bug, 暂时没用
		pushTeamAccount ({commit, state}, account) {
			console.log("往关联账号列表添加", account);
			if (typeof account !== "object") return;
			const {teamAccountList} = state.userInfo;
			teamAccountList.push(account);
			// Vue.set(state.userInfo, "teamAccountList", teamAccountList)
			commit("setUserInfo", state.userInfo);
		},

		removeTeamAccount ({commit, state}, account) {
			console.log("从关联账号列表移除", account);
			if (typeof account !== "object") return;
			const {teamAccountList} = state.userInfo;
			const index = teamAccountList.findIndex(({empid}) => empid == account.empid);
			if (index < 0) return;
			teamAccountList.splice(index, 1);
			// Vue.set(state.userInfo, "teamAccountList", teamAccountList)
			commit("setUserInfo", state.userInfo);
		}
	},

	modules: {
	}
})

// 读取缓存中的用户数据，判断是否登录，并恢复数据到 store
const token = localStorage.getItem("token");
token && store.commit("setToken", token);
const userInfo = JSON.parse(localStorage.getItem("userInfo"));
userInfo && store.commit("setUserInfo", userInfo)

// this.$hasAuthFor 函数用于检测用户权限列表中有无某个api存在
const hasAuthFor = function (apiPath, defaul = false) {

	if (defaul) return defaul;
	const {cenResourceList} = store.state.userInfo;
    // console.log(cenResourceList);

	if (cenResourceList.constructor !== Array) return false;
	else return cenResourceList.some(item => {
        // const same = item.api.endsWith(apiPath);
        const same = item.path.endsWith(apiPath);
		return same;
	})
};

Vue.prototype.$hasAuthFor = hasAuthFor;
export default store;