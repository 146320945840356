<!-- 绑定微信操作介绍 -->
<template>
    <el-dialog class="bind-wx"
        :visible="show"
        append-to-body
        @close="handleClose"
        :title="title"
        width="580px">

        <!-- <img src="./images/help.png" alt="绑定示意图"> -->
        <h3>您的账号还未关注公众号，请扫码关注公众号</h3>
        <p class="tips">如已关注，请使用微信登录移居宝（使用账号手机号），并且在个人中心进行员工认证</p>
        <ul>
            <li v-if="!userInfo.unionidJinding">
                <img src="./images/offical1.jpg" alt="金鼎科技公众号">
                <div class="subscript">关注后可接收"系统账号消息提醒"</div>
            </li>
            <li v-if="!userInfo.unionidShangao">
                <img src="./images/offical2.jpg" alt="金鼎管家公众号">
                <div class="subscript">关注后可接收"公司通知"</div>
            </li>
        </ul>

        <div slot="footer">
            <el-button @click="handleClose" type="primary">知道了</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: 'BindWx',
    props: ["show"],

    data () {
        return {
            title: "温馨提示", // 绑定微信接收系统通知
        }
    },

    methods: {
        handleClose () {
            this.$emit("update:show", false);
        }
    }
}
</script>

<style lang='scss' scoped>
    /deep/ {
        .el-dialog {
            margin-top: 10vh !important;
        }
        .el-dialog__body {
            max-height: 65vh;
            // padding-top: 0;
        }

    }

    h3, ul{
        text-align: center;
    }

    h3 {
        color: orangered;
    }

    .tips {
        margin-top: 10px;
    }

    li {
        margin-top: 30px;

        // & + li {
        //     margin-top: 30px;
        // }

        img {
            display: block;
            width: 154px;
            margin: 0 auto;
        }

        .subscript {
            margin-top: 10px;
        }
    }
</style>