import Vue from "vue";
Vue.config.productionTip = false;
// import VueCryptojs from "vue-cryptojs";
// Vue.use(VueCryptojs);
import router from "@/router";
import store from "@/store";
import "@/assets/css/base_style.scss"; // 基础与全局样式
import "@/assets/element";             // elementUI的配置

import 'viewerjs/dist/viewer.css';
import VueViewer from 'v-viewer';
Vue.use(VueViewer, {
    defaultOptions: {
        zIndex: 99999
    }
})

// import ElementUI from 'element-ui';
// import { ElementTiptapPlugin } from 'element-tiptap';
// // 引入 ElementUI 样式
// import 'element-ui/lib/theme-chalk/index.css';
// // import element-tiptap 样式
// import 'element-tiptap/lib/index.css';
// Vue.use(ElementUI);
// Vue.use(ElementTiptapPlugin, {
//     /* 插件配置项 */
// });
import "@/utils/utils";                // 一些功能
import "@/request";                    // ajax请求配置
import "@/components/forAll.js";       // 全局注册组件
import "@/mixin/forAll.js";            // 全局混入
import '@/directive/dbclick.js'        // 全局指令


// 引用富文本编辑器
// import VueQuillEditor from 'vue-quill-editor'
// import 'quill/dist/quill.core.css'
// import 'quill/dist/quill.snow.css'
// import 'quill/dist/quill.bubble.css'
// Vue.use(VueQuillEditor, /* { default global options } */);


import App from "@/App.vue";
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");