<!-- 移动端二维码 -->
<template>
    <el-dialog class="mobile-index" :visible="show" @close="handleClose" title="各业务系统移动端" width="800px">

        <ul class="list">
            <li v-for="(item, index) in list" :key="index" :ref="'item'+index" class="item">

                <h3 class="item-title" >{{ item.title }}</h3>
                <ul class="image-list">
                    <div class="image-item" style="margin-right:24px;"  v-for="(image, i) in item.qrcode" :key="i">
                        <li v-show="image.src">
                            <img  :src="image.src" alt="二维码">
                            <div class="image-remark">{{ image.title }}</div>
                        </li>
                    </div>
                    <!-- <li v-for="(image, i) in item.qrcode" :key="i" class="image-item">
                        <img  :src="image.src" alt="二维码">
                        <div class="image-remark">{{ image.title }}</div>
                    </li> -->

                </ul>
            </li>
        </ul>

        <el-tabs tab-position="right" class="page-tabs" @tab-click="handleTabClick">
            <el-tab-pane v-for="(item, index) in list" :key="index" :label="item.name" :name="'item'+index"></el-tab-pane>
        </el-tabs>
    </el-dialog>
</template>

<script>
export default {
    name: "MobileIndex",
    props: ["show"],

    data() {
        return {
            list: [
                {
                    name: "房产CRM",
                    title: "客脉二维码",
                    qrcode: [
                        {
                            name: "安卓版本",
                            title: "安卓版本",
                            // src: require("./images/客脉_android.png"),
                            src: '',

                        },
                        {
                            name: "IOS版本",
                            title: "IOS版本",
                            // src: require("./images/客脉_ios.png"),
                            src: '',

                        },
                    ],
                },
                {
                    name: "移民CRM",
                    title: '移民小程序"海客无忧"二维码',
                    qrcode: [
                        {
                            name: "海客无忧",
                            title: "海客无忧",
                            // src: require("./images/haikewuyou.jpg"),
                            src: '',

                        },
                    ],
                },
                {
                    name: "金顶通",
                    title: "金顶通员工下载二维码",
                    qrcode: [
                        {
                            name: "安卓版本",
                            title: "安卓版本",
                            // src: "https://center.jindingaus.com/admin/download/dataFile/jddDownCode.png",
                            src: '',

                        },
                        {
                            name: "IOS版本",
                            title: "IOS版本",
                            // src: "https://center.jindingaus.com/admin/download/dataFile/IOSJDD.png",
                            src: '',

                        },
                    ],
                },
            ],
        };
    },
    watch: {
        show(val, oldval) {
            console.log(val);
            if (val) {
                this.fetchData();
            }
        },
    },
    created() {},
    methods: {
        async fetchData() {
            const loading = this.$loading({
                target: ".mobile-index .el-dialog",
            });
            const res = await this.$axios({
                url: "/api/common/queryAppConfigAllInfo",
                method: "post",
                data: {},
            });
            loading.close();
            if (res.code === 2000) {
                res.data.appConfigAll.forEach((element) => {
                    if (element.sysType == 1) {
                        //客脉IOS版本
                        if (element.image) {
                            this.list[0].qrcode[1].src =
                                this.$baseURL + "/" + element.image;
                        }
                    }
                    if (element.sysType == 2) {
                        //客脉安卓版本
                        if (element.image) {
                            this.list[0].qrcode[0].src =
                                this.$baseURL + "/" + element.image;
                        }
                    }
                    if (element.sysType == 3) {
                        //海客无忧
                        if (element.image) {
                            this.list[1].qrcode[0].src =
                                this.$baseURL + "/" + element.image;
                        }
                    }
                    if (element.sysType == 4) {
                        //金顶通IOS版本
                        if (element.image) {
                            this.list[2].qrcode[1].src =
                                this.$baseURL + "/" + element.image;
                        }
                    }
                    if (element.sysType == 5) {
                        //金顶通安卓版本
                        if (element.image) {
                            this.list[2].qrcode[0].src =
                                this.$baseURL + "/" + element.image;
                        }
                    }
                });
            } else {
                this.$message({
                    message: res.msg || "获取二维码数据失败",
                    type: "warning",
                });
            }
        },
        handleClose() {
            document
                .querySelector(".mobile-index .el-dialog__body")
                .scrollTo(0, 0);
            this.$emit("update:show", false);
        },

        handleTabClick(e) {
            const part_dom = this.$refs[e.name][0];
            part_dom?.scrollIntoView({
                behavior: "smooth",
            });
        },
    },
};
</script>

<style lang='scss' scoped>
/deep/ {
    .el-dialog__body {
        padding-top: 16px;
    }
    .el-tabs__item {
        height: 28px;
        line-height: 28px;
    }
}
.item {
    &:nth-child(n + 2) {
        margin-top: 30px;
    }

    .item-title {
        margin-bottom: 8px;
        padding-left: 8px;
        line-height: 20px;
        border-left: 3px solid #2878ff;
        font-weight: bold;
        font-size: 14px;
        color: #333333;
    }
}
.image-list {
    display: flex;

    li + li {
        margin-left: 64px;
    }

    img {
        display: block;
        width: 106px;
        height: 106px;
        padding: 5px;
        border: 2px solid #e9f1ff;
    }
    .image-remark {
        margin-top: 8px;
        font-size: 16px;
        font-weight: 600;
        color: #333333;
        text-align: center;
    }
}
.page-tabs {
    position: absolute;
    right: 20px;
    top: 80px;
    z-index: 1;
    background-color: white;

    /deep/ {
        .el-tabs__header.is-right {
            margin-left: 1px;
        }
    }
}
</style>