<template>
	<div id="app">
		<router-view v-if="isRouterAlive" />
	</div>
</template>

<script>
	export default {
		data () {
			return {
				isRouterAlive: true
			}
		},
        created(){
        },
        watch: {
            "$route"(value,oldvalue){
                // console.log(value);
                // console.log(oldvalue);
                 //业务系统来的隐藏菜单 stampList waitPostbackAgreementList
                if(value.path=='/customer/channelPhoneList/add'|| //新增/编辑渠道商
                    value.path=='/customer/channelLineList'|| //在线签署渠道协议列表
                    value.path=='/customer/channelPhoneList/previewPdf'|| //渠道合作协议
                    value.path=='/customer/stampList'|| //待审核协议盖章
                    value.path=='/customer/waitPostbackAgreementList'  //待回传协议


                ){
                    this.$store.commit("setShowMenu", { showMenu: false });//隐藏菜单
                }else{
                    this.$store.commit("setShowMenu", { showMenu: true });//显示菜单
                }
            }
        },

		provide () {
			return {
				appReload: this.reload
			}
		},
		methods: {
			reload () {
				this.isRouterAlive = false;
				return this.$nextTick().then(() => {
					this.isRouterAlive = true;
				})
			}
		}
	}
</script>