<!-- 移动端二维码 -->
<template>
    <el-dialog
        class="userful-help"
        :visible="show"
        @close="handleClose"
        title="常见问题"
        width="908px"
    >
        <!-- <img src="./images/problem-img.png" alt="示意图"> -->
        <h3>1.点击业务系统没反应？</h3>
        <img src="./images/helper/1-1.png" alt="苹果电脑帮助示意图">
        <img src="./images/helper/1-2.png" alt="windows帮助示意图">
        <h3>2.苹果电脑清除缓存的方式？</h3>
        <img src="./images/helper/2.png" alt="苹果电脑清除缓存帮助示意图">
        <h3>3.360浏览器登录系统无反应问题？</h3>
        <img src="./images/helper/3.png" alt="360浏览器登录系统无反应帮助示意图">
    </el-dialog>
</template>

<script>
export default {
    name: 'UsefulHelp',
    props: ["show"],

    data () {
        return {}
    },

    methods: {
        handleClose () {
            document.querySelector(".userful-help .el-dialog__body").scrollTo(0,0);
            this.$emit("update:show", false);
        }
    }
}
</script>

<style lang='scss' scoped>
    /deep/ {
        .el-dialog__body {
            padding: 0 50px;
        }
    }

    h3 {
        margin: 30px 0 14px;
        font-family: "宋体", serif;
        font-weight: bold;
        font-size: 20px;
        color: black;
    }

    img {
        display: block;
        margin: 8px 0;
    }
</style>