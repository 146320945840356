<!-- 双关认证 -->
<template>
    <el-dialog class="certificate-leading"
        :visible="show"
        append-to-body
        @close="handleClose"
        :title="title"
        width="732px">

        <!-- <img src="./images/help.png" alt="绑定示意图"> -->
        <h3>一、关注公众号</h3>
        <ul>
            <li>
                <img src="./images/offical1.png" alt="金鼎科技公众号">
                <div class="subscript">关注后可接收"系统账号消息提醒"</div>
            </li>
            <li>
                <img src="./images/offical2.png" alt="金鼎管家公众号">
                <div class="subscript">关注后可接收"公司通知"</div>
            </li>
        </ul>

        <h3>二、登录移居宝进行员工认证</h3>
        <ul>
            <li class="clear-right">
                <img src="./images/yjb.png" alt="移居宝小程序二维码">
                <div class="subscript">1、使用微信扫二维码关注公众号</div>
            </li>
            <li>
                <img src="./images/cert1.png" alt="移居宝员工认证">
                <div class="subscript">2、登录“移居宝”，进入个人中心，点击员工认证</div>
            </li>
            <li>
                <img src="./images/cert2.png" alt="移居宝员工认证结果">
                <div class="subscript">3、查看认证结果</div>
            </li>
        </ul>

        <div slot="footer">
            <el-button @click="handleClose" type="primary">知道了</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: 'CertificateLeading',
    props: ["show"],

    data () {
        return {
            title: "操作方式", // 绑定微信接收系统通知
        }
    },

    methods: {
        handleClose () {
            this.$emit("update:show", false);
        }
    }
}
</script>

<style lang='scss' scoped>
    /deep/ {
        .el-dialog {
            margin-top: 10vh !important;
        }
        .el-dialog__body {
            max-height: 65vh;
        }

    }

    h3 {
        margin-bottom: 32px;
        font-size: 1em;
        font-weight: bold;
        color: #323233;
    }

    ul {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    li {
        flex: 0 0 48%;
        margin-bottom: 32px;
        text-align: center;

        &.clear-right {
            margin-right: 52%;
        }

        img {
            display: block;
            margin: 0 auto;
        }

        .subscript {
            margin-top: 10px;
            font-weight: 500;
            color: #333333;
        }
    }
</style>