// 基础设置

export default {
    path: "/basic",
    name: "Basic",
    component: () =>
        import(
            /* webpackChunkName: "basic" */
            "../views/_1_basic/basic.vue"
        ),
    title: "基础设置",
    icon: "basic",

    children: [
        {
            path: "systemconfig",
            name: "SystemConfig",
            component: () =>
                import(
                    /* webpackChunkName: "basic" */
                    "../views/_1_basic/systemConfig/systemConfig.vue"
                ),
            title: "系统路由",
            descreption: "各个系统的配置",
        },

        {
            path: "menu",
            name: "MenuList",
            component: () =>
                import(
                    /* webpackChunkName: "basic" */
                    "../views/_1_basic/menuList/menuList.vue"
                ),
            title: "菜单管理",
        },

        // 系统字典
        {
            path: "dictionary",
            name: "DictionaryList",
            component: () =>
                import(
                    "../views/_1_basic/dictionary/dictionaryList.vue"
                ),
            title: "系统字典",
        },
        // 渠道商协议设置
        {
            path: "channelContractSet",
            name: "ChannelContractSetList",
            component: () =>
                import(
                    "../views/_1_basic/channelContractSet/channelContractSetList.vue"
                ),
            title: "渠道商协议设置",
        },
        // 公司合同设置
        {
            path: "companyContractSet",
            name: "CompanyContractSet",
            component: () =>
                import(
                    "../views/_1_basic/companyContractSet/companyContractSetList.vue"
                ),
            title: "公司合同设置",
        },
        //#region 角色
        {
            path: "roles",
            name: "RoleList",
            component: () =>
                import(
                    /* webpackChunkName: "basic" */
                    "../views/_1_basic/roleList/roleList.vue"
                ),
            title: "角色列表",
        },
        {
            path: "roles/add",
            name: "RoleAdd",
            component: () =>
                import(
                    /* webpackChunkName: "basic" */
                    "../views/_1_basic/roleEdit/roleEdit.vue"
                ),
            title: "新增角色账号",
            awayMenu: true // 不出现在导航菜单上
        },
        {
            path: "roles/edit/:id",
            name: "RoleEdit",
            component: () =>
                import(
                    /* webpackChunkName: "basic" */
                    "../views/_1_basic/roleEdit/roleEdit.vue"
                ),
            title: "编辑角色账号",
            awayMenu: true // 不出现在导航菜单上
        },
        //#endregion

        {
            path: "posts",
            name: "PostList",
            component: () =>
                import(
                    /* webpackChunkName: "basic" */
                    "../views/_1_basic/postList/postList.vue"
                ),
            title: "岗位列表",
        },

        //#region 组织架构
        {
            path: "organization",
            name: "Organization",
            component: () =>
                import(
                    /* webpackChunkName: "basic" */
                    "../views/_1_basic/organization/organization.vue"
                ),
            title: "组织架构列表",
        },
        // {
        //     path: "organization/add",
        //     name: "OrganizationAdd",
        //     component: () =>
        //         import(
        //             /* webpackChunkName: "basic" */
        //             "../views/_1_basic/organizationEdit/organizationEdit.vue"
        //         ),
        //     title: "组织架构新增",
        //     awayMenu: true
        // },
        // {
        //     path: "organization/edit/:id",
        //     name: "OrganizationEdit",
        //     component: () =>
        //         import(
        //             /* webpackChunkName: "basic" */
        //             "../views/_1_basic/organizationEdit/organizationEdit.vue"
        //         ),
        //     title: "组织架构编辑",
        //     awayMenu: true
        // },
        //#endregion

        {
            path: "logs",
            name: "Logs",
            component: () =>
                import(
                    /* webpackChunkName: "basic" */
                    "../views/_1_basic/logs/logs.vue"
                ),
            title: "操作日志",
        },
        {
            path: "appConfigList",
            name: "AppConfigList",
            component: () =>
                import(
                    /* webpackChunkName: "basic" */
                    "../views/_1_basic/appConfig/appConfigList.vue"
                ),
            title: "移动端配置",
        },
        {
            path: "appConfigList/edit/:id",
            name: "AppConfigList",
            component: () =>
                import(
                    /* webpackChunkName: "basic" */
                    "../views/_1_basic/appConfig/appConfigEdit.vue"
                ),
            title: "编辑配置",
            awayMenu: true // 不出现在导航菜单上
        },

        {
            path: "systemSkin",
            name: "SystemSkin",
            component: () =>
                import(
                    /* webpackChunkName: "basic" */
                    "../views/_1_basic/systemSkin/systemSkin.vue"
                ),
            title: "系统皮肤设置",
            descreption: "系统皮肤设置",
        },

    ],
};