// customer

export default {
    path: "/customer",
    name: "Customer",
    component: () =>
        import(
            "../views/_3_customer/customer.vue"
        ),
    title: "业务数据",
    icon: "user",

    children: [
        {
            path: "list",
            name: "customerPhoneList",
            component: () =>
                import(
                    "../views/_3_customer/customerPhoneList.vue"
                ),
            title: "客户电话列表",
        },
        {
            path: "channelPhoneList",
            name: "channelPhoneList",
            component: () =>
                import(
                    "../views/_3_customer/channelPhoneList.vue"
                ),
            title: "渠道商电话列表",
        },
        {
            path: "channelPhoneList/Detail",
            name: "ChannelDetail",
            component: () =>
                import(
                    "../views/_3_customer/channelDetail.vue"
                ),
            title: "渠道商详情",
        },
        {
            path: "channelPhoneList/add",
            name: "ChannelAdd",
            component: () =>
                import(
                    "../views/_3_customer/channelAdd.vue"
                ),
            title: "新增/编辑渠道商",
        },
        {
            path: "channelLineList",
            name: "LineList",
            component: () =>
                import(
                    "../views/_3_customer/channelLineList.vue"
                ),
            title: "在线签署渠道协议列表",
        },
        {
            path: "channelPhoneList/previewPdf",
            name: "PreviewPdf",
            component: () =>
                import(
                    "../views/_3_customer/channelPreviewPdf.vue"
                ),
            title: "渠道合作协议",
        },
        // /api/calcustomer/queryCalculateCustomerList
        {
            path: "calculateCustomerList",
            name: "CalculateCustomerList",
            component: () =>
                import(
                    "../views/_3_customer/calculateCustomerList.vue"
                ),
            title: "新老客户核算",
        },
        {
            path: "employeeCerformanceList",
            name: "EmployeeCerformanceList",
            component: () =>
                import(
                    "../views/_3_customer/employeeCerformanceList.vue"
                ),
            title: "移民员工绩效",
        },
        {
            path: "managerCerformanceList",
            name: "ManagerCerformanceList",
            component: () =>
                import(
                    "../views/_3_customer/managerCerformanceList.vue"
                ),
            title: "主管经理绩效",
        },
        {
            path: "employeeCerformanceList/Detail",
            name: "employeeCerformanceDetail",
            component: () =>
                import(
                    "../views/_3_customer/empOrManagerDetail.vue"
                ),
            title: "绩效详情",
        },
        {
            path: "managerCerformanceList/Detail",
            name: "managerCerformanceDetail",
            component: () =>
                import(
                    "../views/_3_customer/empOrManagerDetail.vue"
                ),
            title: "绩效详情",
        },
        {
            path: "eventHisLogList",
            name: "EventHisLogList",
            component: () =>
                import(
                    "../views/_3_customer/eventHisLog.vue"
                ),
            title: "业务操作日志",
        },
        {
            path: "stampList",
            name: "StampList",
            component: () =>
                import(
                    "../views/_3_customer/stampList.vue"
                ),
            title: "待审核协议盖章",
        },
        {
            path: "waitPostbackAgreementList",
            name: "SaitPostbackAgreementList",
            component: () =>
                import(
                    "../views/_3_customer/waitPostbackAgreementList.vue"
                ),
            title: "待回传协议",
        },



    ],
}