// tool

export default {
    path: "/tool",
    name: "Tool",
    component: () =>
        import(
            "../views/_4_tool/tool.vue"
        ),
    title: "小工具",
    icon: "user",

    children: [
        {
            path: "emailList",
            name: "emailList",
            component: () =>
                import(
                    "../views/_4_tool/emailList.vue"
                ),
            title: "邮件列表",
        },
        {
            path: "emailList/Detail",
            name: "emailInfoList",
            component: () =>
                import(
                    "../views/_4_tool/emailInfoList.vue"
                ),
            title: "群发邮件明细",
        },
        {
            path: "emailList/sendEmail",
            name: "sendEmail",
            component: () =>
                import(
                    "../views/_4_tool/sendEmail.vue"
                ),
            title: "群发邮件",
        },

    ],
}