// 订单管理

export default {
    path: "/staff",
    name: "Staff",
    component: () =>
        import(
            /* webpackChunkName: "staff" */
            "../views/_2_staff/staff.vue"
        ),
    title: "员工管理",
    icon: "user",

    children: [
        {
            path: "list",
            name: "StaffList",
            component: () =>
                import(
                    /* webpackChunkName: "staff" */
                    "../views/_2_staff/staffList/staffList.vue"
                ),
            title: "员工列表",
        },
        {
            path: "list/add",
            name: "StaffAdd",
            component: () =>
                import(
                    /* webpackChunkName: "staff" */
                    "../views/_2_staff/staffEdit/staffEdit.vue"
                ),
            title: "新增员工",
            awayMenu: true
        },
        {
            path: "list/edit/:id",
            name: "StaffEdit",
            component: () =>
                import(
                    /* webpackChunkName: "staff" */
                    "../views/_2_staff/staffEdit/staffEdit.vue"
                ),
            title: "编辑员工",
            awayMenu: true
        },



        {
            path: "list/logs/:id",
            name: "StaffLogs",
            component: () =>
                import(
                    /* webpackChunkName: "staff" */
                    "../views/_2_staff/staffLogs/staffLogs.vue"
                ),
            title: "员工详情",
            awayMenu: true
        }
    ],
}